import SpeedIcon from '@mui/icons-material/Speed';
import { Button, Divider, Drawer, Grid, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import { DepartmentRole, InvitationTypeEnum } from 'store/interfaces';
import AccountManagersFilter from 'ui-component/Filters/AccountManagersFilter';
import SubCard from 'ui-component/cards/SubCard';
import RoleGuard from 'utils/RoleGuard';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Chip from 'ui-component/extended/Chip';
import { Stack } from '@mui/system';
import NumericFormatCustom from 'ui-component/MoneyInput';
import { LoadingButton } from '@mui/lab';
import { dispatch, useSelector } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import CloseIcon from '@mui/icons-material/Close';
import monthStartAndEndDate from 'components/AccountManagers/Utils/date';
import { setAccountManagersTarget } from 'store/slices/accountManagersTargets/actions';
import useOverlay from 'hooks/useOverlay';

interface SetAccountManagerTargetsProps {
    showTrigger?: boolean;
    defaultSelectedAms?: {
        name: string;
        cognitoId: string;
    }[];
}

const SetAccountManagerTargets = ({ showTrigger = false, defaultSelectedAms = [] }: SetAccountManagerTargetsProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const { error } = useSelector((state) => state.accountManagerTargets);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [openTarget, setOpenTarget] = useState(false);

    const {
        isCurrentOpened,
        open,
        close,
        state: { payload }
    } = useOverlay<SetAccountManagerTargetsProps>('SetAccountManagerTargets');

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'target-set-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        handleClose();
    };
    const SetTargetsSchema = yup.object().shape({
        value: yup.number(),
        organizationsValue: yup.number(),
        date: yup.date().required(`${intl.formatMessage({ id: 'date' })} ${intl.formatMessage({ id: 'is-required' })}`),
        accountManagers: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup.string(),
                    cognitoId: yup.string(),
                    role: yup.mixed<DepartmentRole>()
                })
            )
            .min(1, `${intl.formatMessage({ id: 'account-manager' })} ${intl.formatMessage({ id: 'is-required' })}`)
    });

    const handleClickOpen = () => {
        formik.resetForm();
        setOpenTarget(true);
        open();
    };
    const handleClose = () => {
        formik.resetForm();
        setOpenTarget(false);
        close();
    };

    const initialValues: {
        value: number | undefined;
        organizationsValue: number | undefined;
        accountManagers: { name: string; cognitoId: string }[];
        date: string | null;
    } = {
        value: undefined,
        organizationsValue: undefined,
        accountManagers: [...(payload?.defaultSelectedAms ?? defaultSelectedAms)],
        date: null
    };

    const formik = useFormik({
        initialValues,
        onSubmit(values, formikHelpers) {
            const { startDate, endDate } = monthStartAndEndDate(values.date);
            dispatch(
                setAccountManagersTarget({
                    body: {
                        value: values.value || 0,
                        organizationsValue: values.organizationsValue || 0,
                        from: startDate,
                        to: endDate,
                        accountManagersIds: values.accountManagers.map((am) => am.cognitoId)
                    },
                    runSuccess
                })
            );
        },
        validationSchema: SetTargetsSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const isSubmitDisabled = !formik.values.value && !formik.values.organizationsValue;

    return (
        <RoleGuard groups={[{ name: InvitationTypeEnum.admins }]}>
            {showTrigger && (
                <Button variant="contained" onClick={handleClickOpen} startIcon={<SpeedIcon />}>
                    <FormattedMessage id="set-target" />
                </Button>
            )}

            <Drawer
                anchor={matchDownSM ? 'bottom' : 'right'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={isCurrentOpened}
            >
                <Grid
                    container
                    spacing={gridSpacing}
                    padding={3}
                    flexWrap="nowrap"
                    paddingRight={{ xs: 0, md: 3 }}
                    direction="column"
                    maxWidth={{ xs: '100vw', md: '30vw' }}
                    height={{ xs: '80vh', md: '100vh' }}
                    maxHeight={{ xs: '80vh', md: '100vh' }}
                >
                    <Grid container alignItems="center" justifyContent="space-between" marginTop={2} marginLeft={2}>
                        <Grid item>
                            <Typography variant="h3">
                                <FormattedMessage id="set-target" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <AccountManagersFilter
                            value=""
                            handleSearch={() => {}}
                            handleSelectedAccountManager={(am) => {
                                if (am && !formik.values.accountManagers.find((exAm) => exAm.cognitoId === am.cognitoId)) {
                                    formik.setFieldValue('accountManagers', [
                                        ...formik.values.accountManagers,
                                        { name: am.user.name, cognitoId: am.cognitoId, role: am.role }
                                    ]);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bold">
                            {formik.values.accountManagers.length} <FormattedMessage id="selected-account-managers" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item flexGrow={1}>
                        <Stack overflow="auto" spacing={gridSpacing} maxHeight={140}>
                            {formik.values.accountManagers.map((selectedAm, i) => (
                                <div key={selectedAm.cognitoId}>
                                    <Chip
                                        key={selectedAm.cognitoId}
                                        label={selectedAm.name}
                                        onDelete={() => {
                                            const list = formik.values.accountManagers;
                                            list.splice(i, 1);
                                            formik.setFieldValue('accountManagers', list);
                                        }}
                                    />
                                </div>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <SubCard
                            sx={{
                                bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                            }}
                        >
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={8}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disablePast
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="date"
                                                    variant="standard"
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.date && formik.errors.date)}
                                                    helperText={formik.touched.date && formik.errors.date && 'Date is required'}
                                                />
                                            )}
                                            value={formik.values.date}
                                            onChange={(newValue: Date | null) => {
                                                if (newValue && newValue?.getDate()) formik.setFieldValue('date', newValue.toISOString());
                                                else formik.setFieldValue('date', null);
                                            }}
                                            openTo="month"
                                            label={intl.formatMessage({ id: 'month' })}
                                            views={['month', 'year']}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'target' })}
                                        name="value"
                                        id="set-am-target-form-am-value"
                                        value={formik.values.value}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom as any,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FormattedMessage id="egp" />
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.value && formik.errors.value)}
                                        helperText={formik.touched.value && formik.errors.value}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'selling-brokers' })}
                                        name="organizationsValue"
                                        id="set-am-organization-target-form-am-value"
                                        value={formik.values.organizationsValue}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom as any
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.organizationsValue && formik.errors.organizationsValue)}
                                        helperText={formik.touched.organizationsValue && formik.errors.organizationsValue}
                                    />
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={gridSpacing} justifyContent="flex-end">
                            <Grid item>
                                <Button disabled={formik.isSubmitting} onClick={handleClose}>
                                    <FormattedMessage id="cancel" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    onClick={formik.submitForm}
                                    loading={formik.isSubmitting}
                                    variant="contained"
                                    disabled={isSubmitDisabled}
                                >
                                    <FormattedMessage id="confirm" />
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Drawer>
        </RoleGuard>
    );
};

export default SetAccountManagerTargets;

import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryAccountManagersInterface } from './interfaces';

const ACCOUNT_MANAGERS_APIS = {
    query: (query: QueryAccountManagersInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'account-managers', '?'];
        return formattedQuery(formatQuery, query);
    },
    getById: (accountManagerCId: string) => `${FAUNA_API}account-managers/${accountManagerCId}`,
    assignManager: (accountManagerCId: string, organizationId: string) =>
        `${FAUNA_API}account-managers/${accountManagerCId}/organizations/${organizationId}`,
    unassignManager: (accountManagerCId: string, organizationId: string) =>
        `${FAUNA_API}account-managers/${accountManagerCId}/organizations/${organizationId}`,

    /**
     * @POST
     * @returns account manager
     */
    createAccountManagerFromExistingUser: () => `${FAUNA_API}account-managers`,
    /**
     * @PATCH
     * Update account manager role
     * @returns account manager
     */
    changeAccountManagerRole: (accountManagerCId: string) => `${FAUNA_API}account-managers/${accountManagerCId}`
};

export default ACCOUNT_MANAGERS_APIS;

import { CommonQueryInterface } from 'store/interfaces';
import { OrganizationFullDataInterface } from '../organizations/interfaces';

export interface AccountManagersInitialState {
    loading: boolean;
    error: any;
    refresh: boolean;
    listRefresh: boolean;
    listLoading: boolean;
    listError: any;
    accountManagers: AccountManagersList;
    accountManager?: AccountManagerFullDataInterface;
    Targets?: AccountManagerTarget[];
    achieved: number;
}

export interface AccountManagerInterface {
    cognitoId: string;
    role: AccountManagersRole;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    organizations?: number;
}
export interface AccountManagerFullDataInterface extends AccountManagerInterface {
    user: {
        name: string;
        phone: string;
    };
    team?: {
        id: number;
        name: string;
    };
    _count: {
        organizations: number;
        Organizations?: number;
    };
    Targets?: AccountManagerTargetInterface[];
}

export interface AccountManagerTargetInterface {
    value?: number;
    achieved?: number;
    organizationsValue?: number;
    organizationsAchieved?: number;
    from: string;
    to: string;
    id: number;
    targetAdjust?: AccountManagerTargetAdjustInterface[];
}

export interface AccountManagerTargetAdjustInterface {
    value?: number;
}

export interface AccountManagerOrganizationsListInterface extends AccountManagerInterface {
    user: {
        name: string;
        phone: string;
    };
}

export interface AccountManagerOrganizationFullDataInterface extends AccountManagerInterface {
    organization?: OrganizationFullDataInterface;
}

export interface AccountManagerTargetFullDataInterface {
    Targets?: { value?: number; from: string; to: string; achieved?: number; targetAdjust?: AccountManagerTargetAdjustInterface[] };
}

export interface AccountManagersList {
    page: number;
    pageSize: number;
    pages: number;
    count: number;
    data: AccountManagerFullDataInterface[];
}
export enum AccountManagersRole {
    head = 'Head',
    supervisor = 'Supervisor',
    member = 'Member'
}

export interface QueryAccountManagersInterface extends CommonQueryInterface {
    name?: string;
    cognitoId?: string;
    teamId?: number;
    role?: AccountManagersRole;
    createdAtMin?: string;
    createdAtMax?: string;
    Targets?: AccountManagerTarget[];
}

export interface AccountManagerTarget {
    id?: number;
    value?: number;
    from: string;
    to: string;
    achieved?: number;
    targetAdjust?: AccountManagerTargetAdjustInterface[];
}

export interface AccountManagersTargetsList {
    page: number;
    pageSize: number;
    pages: number;
    count: number;
    data: AccountManagerTargetFullDataInterface[];
}
export interface QueryAccountManagerTarget extends CommonQueryInterface {
    from?: string;
    to?: string;
    accountManagerId?: string;
}

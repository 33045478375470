import CreateAccountManagerActivityDialog from 'components/AccountManagerActivities/Actions/CreateActivityDialog';
import SetAccountManagerTargets from 'components/AccountManagers/Actions/SetTargets';
import CreateFinancingCilDialog from 'components/FinancingCils/Actions/Create';
import CreateEOICTA from 'components/Flows/Actions/CreateEOI/QuickActionBtn';
import CreatePrimaryCilDialogAction from 'components/Flows/Actions/CreatePrimaryCil/CreatePrimaryCilDialogAction';
import CreateSaleClaim from 'components/Flows/Actions/CreateSaleClaim';
import InvitePartnerDialog from 'components/Invitation/Partner';
import CreateOrganizationDialog from 'components/Organizations/Actions/CreateOrganization';
import CreateReservation from 'components/Reservations/Actions/QuickActionCreateReservation';
import ExportDialog from './Tables/NawyTable/Export/ExportDialog';
import CreateBannerDialog from 'components/Banners/CreateBannerDialog';
import EditBannerDialog from 'components/Banners/EditBannerDialog';
import InvitationBrokerDialog from 'components/Brokers/Actions/Invite/Dialog';
import AddLead from 'components/Lead/Actions/AddBuyer';
import DocumentRejectionReasonDialog from 'components/Organization/Actions/InfoTab/DocumentsSection/dialogs/DocumentRejectionReasonDialog';
import { InvitationDialog } from 'components/Brokers/Actions/Invite';
import PaymentTermsDialog from 'components/Flows/Actions/PaymentTerms';

const OverlayProvider = () => (
    <>
        <ExportDialog />
        <CreateFinancingCilDialog />
        <CreatePrimaryCilDialogAction />
        <CreateEOICTA />
        <CreateReservation />
        <CreateSaleClaim />
        <CreateOrganizationDialog />
        <CreateAccountManagerActivityDialog />
        <CreateBannerDialog />
        <EditBannerDialog />
        <InvitePartnerDialog />
        <SetAccountManagerTargets />
        <InvitationBrokerDialog organizationId="" />
        <AddLead />
        <DocumentRejectionReasonDialog />
        <InvitationDialog />
        <PaymentTermsDialog />
    </>
);

export default OverlayProvider;

// assets
import { Alarm } from '@mui/icons-material';
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import AttractionsIcon from '@mui/icons-material/Attractions';
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
// ==============================|| Account Manager Side Menu Items ||============================== //

// const flowsItem: NavItemType = {
//     id: 'flows',
//     type: 'item',
//     url: '/flows',
//     breadcrumbs: false,
//     icon: ReceiptIcon,
//     title: <FormattedMessage id="sales-pipeline" />,

//     allowedAudience: [
//         { name: InvitationTypeEnum.operationManager },
//         { name: InvitationTypeEnum.accountManagers },
//         { name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit' }
//     ],
//     mixpanelEvent: {
//         tab_name: 'Sales Pipeline'
//     }
// };

// const saleClaimItem: NavItemType = {
//     id: 'sale-claims',
//     type: 'item',
//     url: '/sale-claims',
//     breadcrumbs: false,
//     icon: ReceiptIcon,
//     title: <FormattedMessage id="sale-claims" />,

//     allowedAudience: [
//         { name: InvitationTypeEnum.operationManager },
//         { name: InvitationTypeEnum.accountManagers },
//         { name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit' }
//     ],
//     mixpanelEvent: {
//         tab_name: 'Sale Claims'
//     }
// };

const CilsItem: NavItemType = {
    id: 'cils',
    type: 'item',
    url: '/cils',
    breadcrumbs: false,
    icon: Alarm,
    title: <FormattedMessage id="cils" />,
    allowedAudience: [{ name: InvitationTypeEnum.accountManagers }],
    mixpanelEvent: {
        tab_name: 'CILs'
    }
};

// const primaryCilItem: NavItemType = {
//     id: 'primary-cils',
//     type: 'item',
//     url: '/primary-cils',
//     breadcrumbs: false,
//     icon: TopicOutlinedIcon,
//     title: <FormattedMessage id="primary-cils" />,
//     allowedAudience: [
//         { name: InvitationTypeEnum.accountManagers },
//         { name: InvitationTypeEnum.brokers, organizationFeature: 'primaryCilsLimit' }
//     ],
//     mixpanelEvent: {
//         tab_name: 'Primary CILs'
//     }
// };

// const reservationItem: NavItemType = {
//     id: 'reservations',
//     type: 'item',
//     url: '/reservations',
//     breadcrumbs: false,
//     icon: BookOnlineOutlinedIcon,
//     title: <FormattedMessage id="eois-and-reservations" />,
//     allowedAudience: [
//         { name: InvitationTypeEnum.accountManagers },
//         { name: InvitationTypeEnum.brokers, organizationFeature: 'reservationLimit' }
//     ],
//     mixpanelEvent: {
//         tab_name: 'EOIs & Reservations'
//     }
// };

const listingItem: NavItemType = {
    id: 'listings',
    type: 'item',
    url: '/listings',
    breadcrumbs: false,
    icon: AddHomeOutlinedIcon,
    title: <FormattedMessage id="listings" />,
    allowedAudience: [
        { name: InvitationTypeEnum.inventoryManagers },
        { name: InvitationTypeEnum.accountManagers },
        { name: InvitationTypeEnum.brokers, organizationFeature: 'listingLimit' }
    ],
    mixpanelEvent: {
        tab_name: 'Listings'
    }
};

// const leadRequestItems: NavItemType = {
//     id: 'requests',
//     title: <FormattedMessage id="lead-request" />,
//     type: 'item',
//     url: '/leads/requests',
//     icon: ContactsTwoToneIcon,
//     breadcrumbs: false,
//     mixpanelEvent: {
//         tab_name: 'Lead Requests'
//     },
//     allowedAudience: [{ name: InvitationTypeEnum.admins }]
// };

const leadsItem: NavItemType = {
    id: 'leads',
    title: (
        <>
            <FormattedMessage id="added" /> <FormattedMessage id="buyers" />
        </>
    ),
    type: 'item',
    url: '/leads',
    icon: ContactsTwoToneIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.brokers, organizationFeature: 'leadsLimit' }],
    mixpanelEvent: {
        tab_name: 'Add Buyer'
    }
};

const commonItems = [
    // flowsItem,
    // primaryCilItem,
    CilsItem,
    //   reservationItem, saleClaimItem,
    listingItem
    // leadRequestItems
];

const featureItems: NavItemType = {
    id: 'features',
    type: 'group',
    breadcrumbs: false,
    children: [leadsItem, ...commonItems]
};

const featuresCollapse: NavItemType = {
    id: 'features-collapse-group',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    mixpanelEvent: {
        tab_name: 'Features'
    },
    children: [
        {
            id: 'features-collapse',
            type: 'collapse',
            title: <FormattedMessage id="features" />,
            icon: AttractionsIcon,
            breadcrumbs: false,
            children: commonItems
        }
    ]
};

export { featureItems };
export default featuresCollapse;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import Account_MANAGERS_APIS from './apis';
import {
    AccountManagerFullDataInterface,
    AccountManagerInterface,
    AccountManagersList,
    AccountManagersRole,
    QueryAccountManagersInterface
} from './interfaces';

export const queryAccountManagers = createAsyncThunk(
    'accountManagers/query',
    async (
        requestData: {
            queryData: QueryAccountManagersInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<AccountManagersList | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(Account_MANAGERS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAccountManagerById = createAsyncThunk(
    'accountManagers/get-By-id',
    async (
        requestData: {
            accountManagerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<AccountManagerInterface | any> => {
        const { accountManagerCId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(Account_MANAGERS_APIS.getById(accountManagerCId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createAccountManagerFromExistingUser = createAsyncThunk(
    'accountManagers/create-account-manager-from-existing-user',
    async (
        requestData: {
            userData: {
                cognitoId: string;
                phone: string;
                name: string;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<AccountManagerFullDataInterface | any> => {
        const { userData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(Account_MANAGERS_APIS.createAccountManagerFromExistingUser(), userData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateAccountManagerRole = createAsyncThunk(
    'accountManagers/update-account-manager-role',
    async (
        requestData: {
            accountManagerCId: string;
            newRole: {
                role: AccountManagersRole;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<AccountManagerFullDataInterface | any> => {
        const { accountManagerCId, newRole, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(Account_MANAGERS_APIS.changeAccountManagerRole(accountManagerCId), newRole);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

import { CommonInitialState, CommonListInterface, CommonQueryInterface } from 'store/interfaces';
import { BrokerFullDataInterface } from '../brokers/interfaces';
import { Compound } from '../developers/interfaces';
import { CommissionTypeEnum, OrganizationFullDataInterface } from '../organizations/interfaces';
import { UserInterface } from '../users/interfaces';

export interface FlowInterface {
    id: number;
    createdAt: string;
    updatedAt: string;
    monthsOfInstallment?: number;
    downPayment?: number;
    downPaymentDate?: string;
    commissionPercentage?: number;
    contractionDate?: string;
    reservationDate?: string;
    unitId?: string;
    unitValue?: number;
    deleted?: string;
    saleType?: string;
    leadId: number;
    creatorId: string;
    compoundId: number;
    developerId: number;
    propertyTypeId?: number;
    primaryCilId?: number;
    reservationId?: number;
    saleClaimId?: number;
    organizationId?: string;
    stages: FlowStageInterface[];
    lead: FlowLeadInterface;
    buyers?: FlowBuyerInterface[];
    compound: Compound;
    commissionTypes: FlowCommissionTypeInterface[];
    organization?: Partial<OrganizationFullDataInterface>;
    comments?: FlowCommentInterface[];
    propertyType?: {
        id: number;
        name: string;
    };
    documents?: FlowDocumentInterface[];
}
export interface PrimaryCILInterface {
    id: number;
    createdAt: string;
    updatedAt: string;
    monthsOfInstallment?: number;
    downPayment?: number;
    downPaymentDate?: string;
    commissionPercentage?: number;
    contractionDate?: string;
    reservationDate?: string;
    unitId?: string;
    unitValue?: number;
    deleted?: string;
    saleType?: string;
    leadId?: number;
    creatorId?: string;
    compoundId?: number;
    developerId?: number;
    propertyTypeId?: number;
    primaryCilId?: number;
    reservationId?: number;
    saleClaimId?: string; // changed from number to string based on database on 8/4/2024
    organizationId?: string;
    lead: FlowLeadInterface;
    buyers?: FlowBuyerInterface[];
    comments?: FlowCommentInterface[];
    commissionTypes: FlowCommissionTypeInterface[];
    compound: Compound;
    developerContacts?: FlowDeveloperContactInterface[];
    documents?: FlowDocumentInterface[];
    propertyType?: {
        id: number;
        name: string;
    };
    organization?: Partial<OrganizationFullDataInterface>;
    statuses?: Partial<FlowStageStatusInterface>[];
    stages?: FlowStageInterface[];
}
export interface FlowDocumentInterface {
    id: number;
    name: string;
    link: string;
    key: string;
    type: FlowDocumentTypes;
    createdAt: string;
    updatedAt: string;
    flowId: number;
}
export enum FlowDocumentTypes {
    NationalId = 'NationalId',
    ReservationForm = 'ReservationForm',
    BankTransfer = 'BankTransfer',
    DeveloperContract = 'DeveloperContract'
}
export interface FlowCommissionTypeInterface {
    id: number;
    flowId: number;
    type: FlowCommissionTypeEnum;
    updatedBy?: Partial<UserInterface>;
    updatedById: string;
    isCurrent: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface FlowsListInterface extends CommonListInterface {
    data: FlowInterface[];
}
export interface PrimaryCILsListInterface extends CommonListInterface {
    data: PrimaryCILInterface[];
}
export interface FlowsInitialStateInterface extends CommonInitialState {
    flows: FlowsListInterface;
    flow?: FlowInterface;
    primaryCILs: PrimaryCILsListInterface;
    primaryCIL?: PrimaryCILInterface;
    analyticaEOI?: FlowAnalyticaMin;
    analyticaReservation?: FlowAnalyticaMin;
    analyticaSaleClaim?: FlowAnalyticaFull;
    analyticaLoading: boolean;
    analyticaError: any;
}

export interface FlowCommentInterface {
    id: string;
    text: string;
    isPrivate: boolean;
    flowId: number;
    commenter: {
        cognitoId: string;
        name: string;
    };
    createdAt: string;
    updatedAt: string;
}

export interface FlowLeadInterface {
    id: number;
    createdAt: string;
    updateAt: string;
    phoneNumber: string;
    name: string;
    brokerId: string;
    broker?: Partial<BrokerFullDataInterface>;
    organizationId: string;
}

export interface FlowDeveloperContactInterface {
    id: number;
    createdAt: string;
    updateAt: string;
    name: string;
    phone?: string;
    email?: string;
    title?: string;
    note?: string;
    flowId?: number;
}

export interface FlowBuyerInterface {
    id: number;
    createdAt: string;
    updateAt: string;
    name: string;
    phone?: string;
    note?: string;
    flowId?: number;
}

export interface FlowStageInterface {
    id: number;
    createdAt: string;
    updatedAt: string;
    value: FlowStagesEnum;
    expirationDate?: string;
    renewals?: number;
    amountPaid?: number;
    actualDate?: string;
    isCurrent: boolean;
    actor: Partial<UserInterface>;
    actorId: number;
    flowId: number;
    statuses?: Partial<FlowStageStatusInterface>[];
}

export interface FlowStageStatusInterface {
    id: number;
    createdAt: string;
    updatedAt: string;
    comment: string;
    value: FlowStageStatusesEnum;
    actor: Partial<UserInterface>;
    actorId: number;
    isCurrent: number;
    flowStageId: number;
}

export interface UpdateFlowStageStatusInterface {
    value?: FlowStageStatusesEnum;
    comment?: string;
}

export interface CreateFlowPrimaryCilInterface {
    flowId?: number;
    phone: string;
    name: string;
    brokerId?: string;
    compoundId: number;
    developerContactName?: string;
    developerContactEmail?: string;
    developerContactPhone?: string;
    comment?: string;
}

export interface CreateFlowEoiInterface extends CreateFlowPrimaryCilInterface {
    actualDate?: string;
    propertyTypeId: number;
    amountPaid: number;
    flowId?: number;
    unitValue?: number;
    unitId?: string;
    documents?: any[];
}

export interface CreateFlowReservationInterface extends CreateFlowEoiInterface {}

export interface CreateFlowSaleClaimInterface extends CreateFlowReservationInterface {
    contractionDate: string;
    reservationDate: string;
    installmentsYears: number;
    type: string;
    commissionType?: FlowCommissionTypeEnum;
    downPayment: number;
    downPaymentDate: string;
    buyerName: string;
    buyerPhone: string;
    unitValue: number;
    unitId: string;
}

export interface QueryFlowsInterface extends CommonQueryInterface {
    id?: number;
    downPaymentMin?: number;
    downPaymentMax?: number;
    stage?: FlowStagesEnum;
    status?: FlowStageStatusesEnum;
    leadPhone?: string;
    leadName?: string;
    unitValueMin?: number;
    unitValueMax?: number;
    installmentsYearsMin?: number;
    installmentsYearsMax?: number;
    commissionPercentageMin?: number;
    compoundId?: number;
    developerId?: number;
    unitId?: string;
    commissionPercentageMax?: number;
    commissionType?: FlowCommissionTypeEnum;
    saleType?: FlowSaleTypeEnum;
    brokerName?: string;
    brokerId?: string;
    accountManagerId?: string;
    buyerName?: string;
    buyerPhone?: string;
    reviewerId?: string;
    deleted?: boolean;
    organizationId?: string;
    expirationDateMin?: string;
    expirationDateMax?: string;
    contractionDateMin?: string;
    contractionDateMax?: string;
    reservationDateMin?: string;
    reservationDateMax?: string;
    propertyTypeId?: number;
    tierId?: string;
    commissionPercentage?: number;
    statusComment?: string;
    source?: string;
}

export interface UpdateFlowInterface extends Partial<CreateFlowSaleClaimInterface> {
    stageId?: number;
    commissionPercentage?: number;
}

export interface FlowAnalyticaFull {
    total: number;
    count: number;
    sumApproved: number;
    sumInprogress: number;
    sumPreapproved: number;
    Approved: number;
    Pending: number;
    PreApproved: number;
    Rejected: number;
    NoAction: number;
}
export interface FlowAnalyticaMin {
    totalValue: number;
    count: number;
}

export enum FlowCommissionTypeEnum {
    Normal = 'Normal',
    Express = 'Express',
    FirstSalePromo = 'FirstSalePromo',
    CityscapePromo = 'CityscapePromo',
    CityscapePromo2 = 'CityscapePromo2',
    October24Promo = 'October24Promo',
    FreelancerFirstSale = 'FreelancerFirstSale'
}

export enum FlowSaleTypeEnum {
    Resale = 'Resale',
    Primary = 'Primary'
}

export enum FlowStagesEnum {
    PrimaryCil = 'PrimaryCil',
    EOI = 'EOI',
    Reservation = 'Reservation',
    SaleClaim = 'SaleClaim',
    Canceled = 'Canceled'
}

export enum FlowStageStatusesEnum {
    Approved = 'Approved',
    NoAction = 'NoAction',
    Pending = 'Pending',
    PreApproved = 'PreApproved',
    Rejected = 'Rejected'
}

export enum PrimaryCilStageStatusesEnum {
    Approved = 'Approved',
    Pending = 'Pending',
    Rejected = 'Rejected'
}

// types
import { createSlice } from '@reduxjs/toolkit';
import { createAccountManagerFromExistingUser, getAccountManagerById, queryAccountManagers, updateAccountManagerRole } from './actions';
import { AccountManagersInitialState } from './interfaces';

// initial state
const initialState: AccountManagersInitialState = {
    loading: false,
    listLoading: false,
    error: false,
    listError: false,
    refresh: false,
    listRefresh: false,
    accountManagers: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    },
    achieved: 0,
    Targets: []
};

// ==============================|| SLICE - ACCOUNT MANAGERS ||============================== //

const accountManagers = createSlice({
    name: 'accountManagers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryAccountManagers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryAccountManagers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.accountManagers = action.payload;
            })
            .addCase(queryAccountManagers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(getAccountManagerById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getAccountManagerById.fulfilled, (state, action) => {
                state.loading = false;
                state.accountManager = action.payload;
            })
            .addCase(getAccountManagerById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createAccountManagerFromExistingUser.pending, (state) => {
                state.error = false;
            })
            .addCase(createAccountManagerFromExistingUser.fulfilled, (state, action) => {
                state.accountManager = action.payload;
            })
            .addCase(createAccountManagerFromExistingUser.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateAccountManagerRole.pending, (state) => {
                state.error = false;
            })
            .addCase(updateAccountManagerRole.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(updateAccountManagerRole.rejected, (state, action) => {
                state.error = action.payload;
            })

            .addDefaultCase((state, action) => {});
    }
});

export default accountManagers.reducer;

import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import PublicGuard from 'utils/route-guard/PublicGuard';

const SpecialOfferLandingPage = Loadable(lazy(() => import('views/common/SpecialOfferLandingPage')));

// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: (
        <PublicGuard>
            <MinimalLayout />
        </PublicGuard>
    ),
    children: [
        {
            path: '/special-offer',
            element: <SpecialOfferLandingPage />
        }
    ]
};

export default PublicRoutes;

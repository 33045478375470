// third-party
import { combineReducers } from 'redux';
import accountManagerTargets from './slices/accountManagersTargets';

import accountManagerActivities from './slices/accountManagerActivities';
import accountManagers from './slices/accountManagers';

// project imports
import accountManagersTeams from './slices/accountManagerTeams';
import analytica from './slices/analytica';
import brokers from './slices/brokers';
import cils from './slices/cils';
import commissions from './slices/commissions';
import createSaleClaimSlice from './slices/createSaleClaim';
import developers from './slices/developers';
import events from './slices/events';
import general from './slices/general';
import inventoryControl from './slices/inventoryControl';
import inventoryManagers from './slices/inventoryManagers';
import invitations from './slices/invitations';
import invitationTypes from './slices/invitationTypes';
import leadRequests from './slices/leadRequests';
import leads from './slices/leads';
import listingDetails from './slices/listingDetails';
import reviewListing from './slices/listingReview';
import menuReducer from './slices/menu';
import nawyInventory from './slices/nawyInventory';
import notificationTypes from './slices/notifications';
import operationManagers from './slices/operationManagers';
import organizations from './slices/organizations';
import partnerInvitations from './slices/partnerInvitations';
import primaryCils from './slices/primaryCils';
import propertiesListing from './slices/propertiesListing';
import rejectionReasons from './slices/rejectionReasons';
import reservations from './slices/reservations';
import saleClaims from './slices/saleClaims';
import partnersDashboard from './slices/partnersDashboard';
import sales from './slices/sales';
import salesAgents from './slices/salesAgents';
import settings from './slices/settings';
import snackbarReducer from './slices/snackbar';
import tiers from './slices/tiers';
import users from './slices/users';
import activityCategory from './slices/activityCategory';
import activityType from './slices/activityType';
import activityStatus from './slices/activityStatus';
import filters from './slices/filters';
import crmControl from './slices/crmControl';
import banners from './slices/banners';
import flows from './slices/flows';
import developerContacts from './slices/developerContacts';
import overlays from './slices/overlays';
import cilsFilters from './slices/cilsFilters';
import banks from './slices/banks';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    invitations,
    events,
    leadRequests,
    general,
    leads,
    users,
    settings,
    nawyInventory,
    developers,
    organizations,
    banks,
    accountManagers,
    accountManagerTargets,
    tiers,
    commissions,
    brokers,
    saleClaims,
    partnersDashboard,
    sales,
    primaryCils,
    reservations,
    createSaleClaimSlice,
    operationManagers,
    inventoryControl,
    rejectionReasons,
    propertiesListing,
    cils,
    reviewListing,
    listingDetails,
    inventoryManagers,
    invitationTypes,
    salesAgents,
    partnerInvitations,
    accountManagersTeams,
    notificationTypes,
    analytica,
    accountManagerActivities,
    activityCategory,
    activityType,
    activityStatus,
    filters,
    crmControl,
    banners,
    flows,
    developerContacts,
    overlays,
    cilsFilters
});

export default reducer;

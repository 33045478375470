export const SIRENS_API = process.env.REACT_APP_SIRENS_API || 'https://api-staging-sirens.nawy.com/';
export const FAUNA_API = process.env.REACT_APP_FAUNA_API || 'https://api-staging-sirens.nawy.com/';
export const FLORA_API = process.env.REACT_APP_FAUNA_API || 'https://api-staging-flora.nawy.com/';
export const YMIR_API = process.env.REACT_APP_YMIR_API || 'https://staging-ymir.kafrelmatloob.com/v1/';
export const MIDAS_API = process.env.REACT_APP_MIDAS_API || 'https://apigw.staging.kafrelmatloob.com/midas/';

// const API = process.env.REACT_APP_API_MAIN || 'https://api-staging-sirens.nawy.com/';
export const WEBSITE_API = process.env.REACT_APP_WEBSITE_API || 'https://webapi-staging.kafrelmatloob.com/api/';
export const WEBSITE_FRONTEND = process.env.REACT_APP_WEBSITE_URL || 'https://test3.nawy.com/';
export const API_VERSION = process.env.REACT_APP_VERSION || '0.0';
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL || 'https://sirens-staging-docs.nawy.com/';
export const CRM_NAWY_BASE_URL = process.env.REACT_APP_CRM_API_MAIN || 'https://crmstagingapi.kafrelmatloob.com/';
export const CRM_TREX_BASE_URL = process.env.REACT_APP_CRM_API_TREX || 'https://crmstagingapi.t-rex.realestate/';
export const E_REALTY_EXTERNAL_URL = process.env.REACT_APP_EREALTY_EXTERNAL_URL || 'https://erealty.nawy.com';
export const E_REALTY_INTERNAL_URL = process.env.REACT_APP_EREALTY_INTERNAL_URL || 'https://partners-3d.kafrelmatloob.com';
export const LAUNCHES_URL = `${process.env.REACT_APP_WEBSITE_URL}new-launches?partners=true`;
export const TASKEEN_EGYPT_API = 'https://franchise-api.taskeenegypt.com/api';

export const queryData = (queryName: string, data?: string | number | boolean) =>
    (data && data !== undefined) || data === false
        ? typeof data === 'string' && data.includes('array')
            ? `${data.replaceAll('array', '')}`
            : `${queryName}=${data}`
        : '';

export const formattedQuery = (formatQuery: string[], query: object) => {
    const queryValues = Object.values(query);
    Object.keys(query).forEach((queryKey, i) => {
        if (queryValues[i] && queryKey) formatQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
    });
    return formatQuery.join('');
};

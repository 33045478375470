import { FAUNA_API, formattedQuery } from 'store/apis';

import { QueryBrokersInterface } from '../brokers/interfaces';
import { DocumentTypes, QueryOrganizationDocuments, QueryOrganizations } from './interfaces';
import { InvitationTypeEnum } from 'store/interfaces';

export const allowedToQueryOrganizations = [
    { name: InvitationTypeEnum.admins },
    { name: InvitationTypeEnum.accountManagers },
    { name: InvitationTypeEnum.operationManager }
];

export const allowedOrganizations = {
    query: allowedToQueryOrganizations
};

const ORGANIZATIONS_APIS = {
    // post
    create: () => `${FAUNA_API}organizations`,
    // get
    query: (query: QueryOrganizations) => {
        const formatQuery: string[] = [FAUNA_API, 'organizations', '?'];
        return formattedQuery(formatQuery, query);
    },
    // get
    getById: (id: string) => `${FAUNA_API}organizations/${id}`,
    // patch
    updateInfo: (id: string) => `${FAUNA_API}organizations/${id}`,
    // patch
    addDevelopersRelations: (id: string) => `${FAUNA_API}organizations/${id}/add-developers`,
    // patch
    removeDevelopersRelations: (id: string) => `${FAUNA_API}organizations/${id}/remove-developers`,
    // patch
    updateLimits: (id: string) => `${FAUNA_API}organizations/${id}/limits`,
    // delete
    delete: (id: string) => `${FAUNA_API}organizations/${id}`,
    // get
    queryDocuments: (id: string, query: QueryOrganizationDocuments) => {
        const q = new URLSearchParams(query as any).toString() ?? '';
        return `${FAUNA_API}organizations/${id}/documents/query?${q}`;
    },
    // put
    uploadDocument: (id: string) => `${FAUNA_API}organizations/${id}/documents`,
    // put
    editDocumentState: (id: string) => `${FAUNA_API}organizations/${id}/documents`,
    // delete
    deleteDocument: (id: string, documentId: string) => `${FAUNA_API}organizations/${id}/documents/${documentId}`,
    // Patch
    updateDocument: (id: string) => `${FAUNA_API}organizations/${id}/documents/batch-update-documents`,
    // Get
    getActiveDocumentsByType: (id: string, type: DocumentTypes | keyof typeof DocumentTypes) => {
        const q = new URLSearchParams({ type }).toString() ?? '';
        return `${FAUNA_API}organizations/${id}/documents/get-active-documents-by-type?${q}`;
    },
    // put
    subscribeToTier: (id: string, tierId: string) => `${FAUNA_API}organizations/${id}/subscription/${tierId}`,
    // delete
    unsubscribe: (id: string) => `${FAUNA_API}organizations/${id}/subscription`
};

export const ORGANIZATION_BROKERS_APIS = {
    // post
    invite: (organizationId: string) => `${FAUNA_API}organizations/${organizationId}/brokers`,
    // get
    query: (organizationId: string, query: QueryBrokersInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'organizations/', organizationId, '/brokers', '?'];
        return formattedQuery(formatQuery, query);
    },
    // get
    getById: (organizationId: string, brokerCId: string) => `${FAUNA_API}organizations/${organizationId}/brokers/${brokerCId}`,
    // patch
    updateRole: (organizationId: string, brokerCId: string) => `${FAUNA_API}organizations/${organizationId}/brokers/${brokerCId}`,
    // delete
    cancelInvitation: (organizationId: string, brokerTemId: string) =>
        `${FAUNA_API}organizations/${organizationId}/brokers/${brokerTemId}/cancel-invitation`,
    // delete
    softDelete: (organizationId: string, brokerCId: string) => `${FAUNA_API}organizations/${organizationId}/brokers/${brokerCId}`,
    // move to another organization
    /**
     * @deprecated - use move broker in broker slice
     * @param organizationId
     * @param brokerCId
     * @param targetOrganizationId
     * @returns
     */
    moveToAnotherOrganization: (organizationId: string, brokerCId: string, targetOrganizationId: string) =>
        `${FAUNA_API}organizations/${organizationId}/brokers/${brokerCId}/transfer/${targetOrganizationId}`,
    deleteBroker: (organizationId: string, brokerCId: string) => `${FAUNA_API}organizations/${organizationId}/brokers/${brokerCId}`
};

export const ORGANIZATION_CONTACT_INFO_APIS = {
    /**
     * @POST
     * @param organizationId
     * @returns OrganizationContactInfoInference
     */
    create: (organizationId: string) => `${FAUNA_API}organizations/${organizationId}/contact-info`,
    /**
     * @GET
     * @param organizationId
     * @returns OrganizationContactInfoInference[]
     */
    getList: (organizationId: string) => `${FAUNA_API}organizations/${organizationId}/contact-info`,
    /**
     * @PATCH
     * @param organizationId
     * @param contactInfoId
     * @returns OrganizationContactInfoInference
     */
    update: (organizationId: string, contactInfoId: number) => `${FAUNA_API}organizations/${organizationId}/contact-info/${contactInfoId}`,
    /**
     * @DELETE
     * @param organizationId
     * @param contactInfoId
     * @returns String
     */
    delete: (organizationId: string, contactInfoId: number) => `${FAUNA_API}organizations/${organizationId}/contact-info/${contactInfoId}`
};

export const ORGANIZATION_LOCATIONS_APIS = {
    /**
     * @POST
     * @param organizationId
     * @returns OrganizationLocationsInterface
     */
    create: (organizationId: string) => `${FAUNA_API}organizations/${organizationId}/locations`,
    /**
     * @GET
     * @param organizationId
     * @returns OrganizationLocationsInterface[]
     */
    getList: (organizationId: string) => `${FAUNA_API}organizations/${organizationId}/locations`,
    /**
     * @PATCH
     * @param organizationId
     * @param locationId
     * @returns OrganizationLocationsInterface
     */
    update: (organizationId: string, locationId: number) => `${FAUNA_API}organizations/${organizationId}/locations/${locationId}`,
    /**
     * @DELETE
     * @param organizationId
     * @param locationId
     * @returns String
     */
    delete: (organizationId: string, locationId: number) => `${FAUNA_API}organizations/${organizationId}/locations/${locationId}`
};
export const ORGANIZATION_AREAS_APIS = {
    /**
     * @POST
     * @param organizationId
     * @param areaName
     * @returns OrganizationAreaInterface
     */
    addArea: (organizationId: string, areaName: string) => `${FAUNA_API}organizations/${organizationId}/areas/${areaName}`,

    /**
     * @DELETE
     * @param organizationId
     * @param areaName
     * @returns String
     */
    removeArea: (organizationId: string, areaName: string) => `${FAUNA_API}organizations/${organizationId}/areas/${areaName}`
};
export const ORGANIZATION_PAYMENT_INFO_APIS = {
    /**
     * @POST
     * @param organizationId
     * @returns OrganizationPaymentInformationInterface
     */
    addPaymentInfo: (organizationId: string) => `${FAUNA_API}organizations/${organizationId}/payment-information`,

    /**
     * @PATCH
     * @param organizationId
     * @param PaymentInformationId
     * @returns OrganizationPaymentInformationInterface
     */
    updatePaymentInfo: (organizationId: string, paymentInfoId: number) =>
        `${FAUNA_API}organizations/${organizationId}/payment-information/${paymentInfoId}`,

    /**
     * @DELETE
     * @param organizationId
     * @param PaymentInformationId
     * @returns String
     */
    removePaymentInfo: (organizationId: string, paymentInfoId: number) =>
        `${FAUNA_API}organizations/${organizationId}/payment-information/${paymentInfoId}`
};
export const ORGANIZATION_TERMS_APIS = {
    /**
     * @POST
     * @param organizationId
     * @returns OrganizationPaymentInformationInterface
     */
    addPaymentInfo: (organizationId: string) => `${FAUNA_API}organizations/${organizationId}/payment-information`
};

export const ORGANIZATION_COMPETITION_APIS = {
    /**
     * @GET
     * @param organizationId
     * @returns OrganizationCompetitionInterface
     */
    getMegaCompetitionValue: () => `${FAUNA_API}organizations/competition/mega-competition-sales`,
    /**
     * @GET
     * @param organizationId
     * @returns OrganizationLeaderboardInterface
     */
    getLeaderboard: () => `${FAUNA_API}organizations/competition/leaderboard`
};

export default ORGANIZATIONS_APIS;

import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { Button, Grid, Typography } from '@mui/material';
import AlertDialog from 'ui-component/DeleteDialog';
import { LoadingButton } from '@mui/lab';
import { createTypeConfig, updateTypeConfig } from 'store/slices/notifications/actions';
import { openSnackbar } from 'store/slices/snackbar';
import { Channel, CreateTypeConfigV1Dto, UpdateTypeConfigV1Dto, UsersInterface } from 'store/slices/notifications/interfaces';
import DefaultUsers from '../DefaultUsers';
import { useTheme } from '@mui/material/styles';
import TemplateBody from '../TemplateBody';

const SystemConfig = ({ addNew, onCancel }: { addNew: boolean; onCancel?: React.Dispatch<React.SetStateAction<Channel | undefined>> }) => {
    const intl = useIntl();
    const theme = useTheme();
    const [edit, setEdit] = useState(false);
    const UpdateLinkSchema = yup.object().shape({
        templateBody: yup.string().required(intl.formatMessage({ id: 'template-body-required' }))
    });
    const { notificationType } = useSelector((state) => state.notificationTypes);

    const checkDefaultUsersChange = (array1: UsersInterface[], array2: UsersInterface[]) => {
        array1.sort((a, b) => a.cognitoId.localeCompare(b.cognitoId));
        array2.sort((a, b) => a.cognitoId.localeCompare(b.cognitoId));
        return !(array1.length === array2.length && array1.every((value, index) => value.cognitoId === array2[index].cognitoId));
    };

    const handleToggleEdit = (formik: FormikHelpers<any>) => {
        setEdit(!edit);
        formik.resetForm();
    };
    const submit = (values: any, formik: FormikHelpers<any>) => {
        formik.setSubmitting(true);

        if (notificationType) {
            if (addNew) {
                const filteredData: CreateTypeConfigV1Dto = {
                    typeId: notificationType.typeId,
                    channel: Channel.system,
                    body: values.templateBody
                };
                if (checkDefaultUsersChange([...values.defaultUsers], [...notificationType.defaultUsersInfo]))
                    filteredData.defaultUsers = values.defaultUsers.map((user: UsersInterface) => user.cognitoId);
                dispatch(
                    createTypeConfig({
                        createData: filteredData,
                        runSuccess
                    })
                );
            } else {
                const config = notificationType.configurations.find((x) => x.channel === Channel.system);
                if (config) {
                    const filteredData: UpdateTypeConfigV1Dto = {
                        typeId: notificationType.typeId
                    };
                    if (values.templateBody !== notificationType?.configurations.find((x) => x.channel === Channel.system)?.body)
                        filteredData.body = values.templateBody;
                    if (config.defaultUsersFullInfo)
                        if (checkDefaultUsersChange([...values.defaultUsers], [...config.defaultUsersFullInfo]))
                            filteredData.defaultUsers = values.defaultUsers.map((user: UsersInterface) => user.cognitoId);

                    if (Object.keys(filteredData).length === 1) {
                        formik.setSubmitting(false);
                        return;
                    }
                    dispatch(
                        updateTypeConfig({
                            id: config.configId,
                            updateData: filteredData,
                            runSuccess
                        })
                    );
                }
            }
        }
        formik.setSubmitting(false);
        handleToggleEdit(formik);
    };

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'notification-type-updated-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };

    useEffect(() => {
        if (addNew) setEdit(true);
    }, [addNew]);

    return (
        <Formik
            initialValues={{
                defaultUsers: addNew
                    ? notificationType?.defaultUsersInfo ?? []
                    : notificationType?.configurations.find((x) => x.channel === Channel.system)?.defaultUsersFullInfo ?? [],
                templateBody: notificationType?.configurations.find((x) => x.channel === Channel.system)?.body ?? ''
            }}
            onSubmit={submit}
            validationSchema={UpdateLinkSchema}
            enableReinitialize
        >
            {(formik) => (
                <Form style={{ position: 'relative', zIndex: '1' }}>
                    <MainCard
                        title={intl.formatMessage({ id: 'system-text' })}
                        secondary={
                            !edit ? (
                                <Grid container spacing={gridSpacing}>
                                    {notificationType?.configurations.find((x) => x.channel === Channel.system) && (
                                        <Grid item>
                                            <AlertDialog
                                                type="button"
                                                title={intl.formatMessage({ id: 'remove' })}
                                                onConfirm={() => {
                                                    formik.setFieldValue('templateBody', '');
                                                    formik.submitForm();
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button variant="outlined" size="small" onClick={() => handleToggleEdit(formik)}>
                                            <FormattedMessage id="edit" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={gridSpacing}>
                                    <Grid item>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            color="error"
                                            size="small"
                                            onClick={() => handleToggleEdit(formik)}
                                        >
                                            <FormattedMessage id="cancel" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton
                                            loading={formik.isSubmitting}
                                            variant="contained"
                                            size="small"
                                            onClick={formik.submitForm}
                                        >
                                            <FormattedMessage id="confirm" />
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            )
                        }
                    >
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <DefaultUsers edit={edit} formik={formik} />
                            </Grid>
                            <Grid item xs={12} sx={{ paddingBottom: '150px', height: '100%' }}>
                                <Typography
                                    sx={{
                                        marginBottom: '5px'
                                    }}
                                    color={formik.errors.templateBody ? theme.palette.error.main : theme.palette.text.primary}
                                >
                                    <FormattedMessage id="template-body" />:
                                </Typography>{' '}
                                <TemplateBody singleLine={false} formik={formik} readOnly={!edit} />
                            </Grid>
                        </Grid>
                    </MainCard>
                </Form>
            )}
        </Formik>
    );
};

export default SystemConfig;

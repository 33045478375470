import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
import { E_REALTY_EXTERNAL_URL } from 'store/apis';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import { TierViewsEnum } from 'store/slices/tiers/interfaces';

const eRealty: NavItemType = {
    id: 'e-realty-external',
    title: <FormattedMessage id="e-realty" />,
    type: 'item',
    target: true,
    url: E_REALTY_EXTERNAL_URL,
    icon: ThreeDRotationIcon,
    mixpanelEvent: {
        tab_name: 'Erealty'
    },
    allowedAudience: [
        { name: InvitationTypeEnum.admins },
        { name: InvitationTypeEnum.accountManagers },
        {
            name: InvitationTypeEnum.brokers,
            organizationTierView: TierViewsEnum.brokerView,
            roles: [OrganizationRole.owner, OrganizationRole.manager]
        },
        { name: InvitationTypeEnum.internalAgents },
        { name: InvitationTypeEnum.inventoryManagers },
        { name: InvitationTypeEnum.nawyInventory },
        { name: InvitationTypeEnum.operationManager }
    ]
};

const eRealtyItem: NavItemType = {
    id: 'eRealty',
    type: 'group',
    children: [eRealty]
};
export default eRealtyItem;

import { useParams, useNavigate } from 'react-router-dom';

// project imports
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { CRM_TREX_BASE_URL, CRM_NAWY_BASE_URL } from 'store/apis';
import axios from 'axios';
import useMixPanel from 'hooks/useMixPanel';

// ==============================|| INTERNAL AGENTS GUARD ||============================== //

/**
 * @summary CRM guard
 * @param {PropTypes.node} children children element/node
 */
const CRMGuard = ({ children }: GuardProps) => {
    const { token, organization } = useParams();
    const { handleSetUser } = useMixPanel();

    const navigate = useNavigate();

    const CRMs: { [key: string]: string } = {
        nawy: CRM_NAWY_BASE_URL,
        trex: CRM_TREX_BASE_URL,
        dre: 'https://dre-api.cooingestate.com/',
        housera: 'https://housera-api.cooingestate.com/',
        investate: 'https://investate-api.cooingestate.com/',
        'gy-homes': 'https://gy-homes-api.cooingestate.com/',
        taskeen: 'https://api.taskeenegypt.com/',
        acasamia: 'https://acasamia-api.cooingestate.com/',
        'egy-emirates-prop': 'https://egy-emirates-prop-api.cooingestate.com/',
        travco: 'https://travco-api.cooingestate.com/',
        keyrealestateegypt: 'https://api-v2.keyrealestateegypt.com/',
        hakim: 'https://hakim-api.taskeenegypt.com/',
        homy: 'https://homy-api.taskeenegypt.com/'
    };

    useEffect(() => {
        // const baseUrl = handleBaseUrl();
        if (!organization) navigate('auth', { replace: true });
        else {
            const baseUrl = CRMs[organization];
            axios
                .get(`${baseUrl}api/users/validate_token?token=${token}`)
                .then((response) => {
                    const userData = response.data.data;
                    handleSetUser(userData);
                })
                .catch((error) => {
                    navigate(`../auth?redirectTo=${window.location.pathname}`, { replace: true });
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return children;
};

export default CRMGuard;
